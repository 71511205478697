
import { saveAs } from "file-saver";
import { defineComponent, reactive, toRefs, ref } from "vue";
import loading from "vue";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";
import { ElNotification } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "export-audit-modal",
  components: {},
  data() {
    return {
      loading: false,
      value1: new Date(),
      today: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      auditData: [],
      exportFlag: "False",
      // render: false,
      organizationId: "",
      shortcuts: [
        {
          text: "Last week",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
    };
  },
  methods: {
    resetform()
    {
      
           (this.$refs["resetForm"] as any).click();
           
    },
    callUpdate(orgId) {
      this.organizationId = orgId;
    },
    download() {   
      this.loading=true;   
      var keys = Object.keys(this.auditData[0]);
      let csv = "";
      csv += keys.join(",") + "\n";
      for (var line of this.auditData) {
        csv += keys.map((key) => JSON.stringify(line[key])) + "\n";
      }
      console.log(csv);
      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      this.loading=false;
      saveAs(blob, "AuditData.csv");
          
    },
    formReset() {
      (this.value1 = new Date()),
        (this.today = new Date()),
        (this.startDate = new Date()),
        (this.endDate = new Date()),
        (this.auditData = []),
        (this.exportFlag = "False"),
        (this.organizationId = "");
    },
    getaudit() {
      // this.render = false
      // const loading = ElLoading.service({
      //   lock: true,
      //   text: 'Loading',
      //   background: 'rgba(0, 0, 0, 0.7)',
      // })
      var todaydate =
        this.today.getFullYear() +
        "-" +
        (this.today.getMonth() + 1) +
        "-" +
        this.today.getDate();
      if (this.value1 == null) {
        // this.getaudit()
        ElNotification({
          title: "Error",
          message: "Date cannot be null",
          type: "error",
          duration: 2000,
          position: "top-right",
        });
      } else if (this.value1 != null && Object.keys(this.value1).length != 2) {      
        var valuedate =
          this.value1.getFullYear() +
          "-" +
          (this.value1.getMonth() + 1) +
          "-" +
          this.value1.getDate();
        if (valuedate == todaydate) {
            this.loading=false;
          ElNotification({
            title: "Error",
            message: "Date cannot be null",
            type: "error",
            duration: 2000,
            position: "top-right",
          });          
        }
      } else {
        this.startDate = this.value1[0];
        this.endDate = this.value1[1];
        this.exportFlag = "True";
        ApiService.get(
          "/auditTrail?organizationId=" +
            this.organizationId +
            "&dateFrom=" +
            this.startDate +
            "&dateTo=" +
            this.endDate +
            "&export=" +
            this.exportFlag
        )
          .then(({ data }) => {
            // sessionStorage.setItem("response", JSON.stringify(data))
            // loading.close()
            // this.originalData = data.data;
            // this.displayData(data.data);  
            this.loading=true;          
            this.auditData = data.data;
            if(this.auditData.length>0){
            this.download();
             ElNotification({
              title: "Success",
              message: "Logs exported successfully!",
              type: "success",
              duration: 2000,
              position: "top-right",
            });
            (this.$refs["resetForm"] as any).click();
            hideModal(this.exportAuditModalRef);
            this.$emit("changeTitle", "false");
           
            }
           else{
             this.loading=false;      
            ElNotification({   
              title: "Error",
              message: "No data in the selected date range.",
              type: "error",
              duration: 3000,
              position: "top-right",
            }); 
            //  (this.$refs["resetForm"] as any).click();
            // hideModal(this.exportAuditModalRef);
            // this.$emit("changeTitle", "false");

           }
          })
          // .catch((error) => {                    
               
          // });
      }
    },
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const exportAuditModalRef = ref<null | HTMLElement>(null);
    //   const formRef = ref<null | HTMLFormElement>(null);
    //   const loading = ref<boolean>(false);
    //   const state = reactive({
    //     shortcuts: [
    //       {
    //         text: "Last week",
    //         value: () => {
    //           const end = new Date();
    //           const start = new Date();
    //           start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    //           return [start, end];
    //         },
    //       },
    //       {
    //         text: "Last month",
    //         value: () => {
    //           const end = new Date();
    //           const start = new Date();
    //           start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
    //           return [start, end];
    //         },
    //       },
    //       {
    //         text: "Last 3 months",
    //         value: () => {
    //           const end = new Date();
    //           const start = new Date();
    //           start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
    //           return [start, end];
    //         },
    //       },
    //     ],
    //   });

    //   const formData = ref({
    //     dateRange: [],
    //     exportFormat: "",
    //   });

    //   const rules = ref({
    //     dateRange: [
    //       {
    //         required: true,
    //         message: "Date range is required",
    //         trigger: "change",
    //       },
    //     ],
    //   });

    //   const submit = () => {
    //     if (!formRef.value) {
    //       return;
    //     }

    //     formRef.value.validate((valid) => {
    //       if (valid) {
    //         loading.value = true;

    //         setTimeout(() => {
    //           loading.value = false;

    //           Swal.fire({
    //             text: "Exported Successfully!!",
    //             icon: "success",
    //             buttonsStyling: false,
    //             confirmButtonText: "Ok, got it!",
    //             customClass: {
    //               confirmButton: "btn btn-primary",
    //             },
    //           }).then(() => {
    //             // window.location.reload();
    //           });
    //         }, 2000);
    //       } else {
    //         Swal.fire({
    //           text: "Sorry, looks like there are some errors detected, please try again.",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });
    //         return false;
    //       }
    //     });
    //   };

    return {
      // ...toRefs(state),
      // formData,
      // rules,
      submitButtonRef,
      exportAuditModalRef,
      // formRef,
      // loading,
    };
  },
});
