
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import ExportAudit from "@/components/modals/forms/ExportAudit.vue";
import FilterAuditLog from "@/components/modals/forms/FilterAuditLog.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Globals from "@/views/common/Globals.vue";
import ApiService from "@/core/services/ApiService";
import { ElLoading } from "element-plus";
import { ElNotification } from "element-plus";
import axios from "axios";
import { mapState } from "vuex";
import router from "@/router";

export default defineComponent({
  name: "AuditTrail",
  mixins: [Globals],
  components: {
    Datatable,
    ExportAudit,
    FilterAuditLog,
  },

  data() {
    return {
      search: "",
      auditTrail: true,
      render: false,
      auditdata: [],
      flag: false,
      originalData: [],
      pageNumber: 0,
      displayPage: 1,
      nextDisabled: false,
      prevDisabled: true,

      shortcuts: [
        {
          text: "Last week",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          },
        },
        {
          text: "Last month",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          },
        },
        {
          text: "Last 3 months",
          value: () => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          },
        },
      ],
      value1: new Date(),
      today: new Date(),
      startDate: new Date(),
      endDate: new Date(),
    };
  },
  methods: {
    exportAudit(orgId) {
      (this.$refs["exportAuditForm"] as any).callUpdate(orgId);
    },
    next() {
      if (this.auditdata.length >= 25) {
        this.pageNumber = this.pageNumber + 1;
        this.displayPage = this.pageNumber + 1;
        if (Object.keys(this.value1).length == 2) {
          this.show();
        } else {
          this.getaudit();
        }
        this.nextDisabled = false;
      } else if (this.auditdata.length == 0) {
        ElNotification({
          title: "Error",
          message: "No more Audit logs",
          type: "error",
          duration: 2000,
          position: "top-right",
        });
        this.nextDisabled = true;
      } else {
        ElNotification({
          title: "Error",
          message: "No more logs",
          type: "error",
          duration: 2000,
          position: "top-right",
        });
      }
    },
    previous() {
      if (this.pageNumber != 0) {
        this.pageNumber = this.pageNumber - 1;
        this.displayPage = this.pageNumber + 1;
        if (Object.keys(this.value1).length == 2) {
          this.show();
        } else this.getaudit();
      } else {
        ElNotification({
          title: "Error",
          message: "Already on first page",
          type: "error",
          duration: 2000,
          position: "top-right",
        });
        this.prevDisabled = true;
      }
    },
    displayData(data) {
      this.auditdata = JSON.parse(JSON.stringify(data));

      this.render = true;

      if (this.auditdata.length == 0) {
        this.render = false;
        this.flag = true;
      } else {
        this.render = true;
        this.flag = false;
      }
    },
    getaudit() {
      this.render = false;
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.pageNumber != 0) this.prevDisabled = false;
      ApiService.get(
        "/auditTrail?organizationId=" +
          this.globalData.organizationId +
          "&pageNumber=" +
          this.pageNumber
      )
        .then(({ data }) => {
          loading.close();

          this.originalData = data.data;
          this.displayData(data.data);
        })
        .catch((error) => {
          loading.close();
          this.displayData([]);
          ElNotification({
            title: "Warning",
            message: "No data in Audit Trail for the selected date range",
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
        })
        .catch((error) => {
          loading.close();
          if (this.pageNumber == 0) {
            ElNotification({
              title: "Error",
              message: JSON.stringify(
                error.response.data["errors"][0]["errorMessage"]
              ),
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          } else {
            ElNotification({
              title: "Error",
              message: "No more logs",
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
    },
    showLatest() {
     this.displayPage=1;
      this.pageNumber = 0;
      this.getaudit();
    },
    show() {
      var todaydate =
        this.today.getFullYear() +
        "-" +
        (this.today.getMonth() + 1) +
        "-" +
        this.today.getDate();

      if (this.value1 == null) {
        this.getaudit();
        ElNotification({
          title: "Error",
          message: "Date cannot be null",
          type: "error",
          duration: 2000,
          position: "top-right",
        });
      } else if (this.value1 != null && Object.keys(this.value1).length != 2) {
        var valuedate =
          this.value1.getFullYear() +
          "-" +
          (this.value1.getMonth() + 1) +
          "-" +
          this.value1.getDate();
        if (valuedate == todaydate) {
          ElNotification({
            title: "Error",
            message: "Date cannot be null",
            type: "error",
            duration: 2000,
            position: "top-right",
          });
        }
      } else {
        this.startDate = this.value1[0];
        this.endDate = this.value1[1];
        if (this.pageNumber != 0) this.prevDisabled = false;
        // this.pageNumber = 0

        this.render = false;
        const loading = ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ApiService.get(
          "/auditTrail?organizationId=" +
            this.globalData.organizationId +
            "&dateFrom=" +
            this.startDate +
            "&dateTo=" +
            this.endDate +
            "&pageNumber=" +
            this.pageNumber
        )
          .then(({ data }) => {
            loading.close();
            this.displayData(data.data);
          })
          .catch((error) => {
            loading.close();
            if (this.pageNumber == 0) {
              ElNotification({
                title: "Error",
                message: JSON.stringify(
                  error.response.data["errors"][0]["errorMessage"]
                ),
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            } else {
              ElNotification({
                title: "Error",
                message: "No more logs",
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            }
          });
      }
    },
  },

  mounted() {
    this.getaudit();
  },
  setup() {
    const tableHeader = ref([
      {
        name: "User ID",
        key: "userId",
        sortable: true,
      },

      {
        name: "Transaction Type",
        key: "transactionType",
        sortable: true,

      },
      {
        name: "Created Date",
        key: "created",
        sortable: true,
      },
      {
        name: "IP Address",
        key: "ipAddress",
      },
      {
        name: "Details",
        key: "details",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Audit Trail", ["API"]);
    });

    return {
      tableHeader,
    };
  },
});
